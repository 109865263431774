import React from "react";
import { Box, Card, CardContent } from "@mui/material";

interface Props {
  title?: string | React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
  titleStyle?: React.CSSProperties;
}

interface State {}
const pageStyle = {
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "8px",
};
const explorePageStyle = {
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "0px",
};
class CardContainer extends React.Component<Props, State> {
  render() {
    const { title, children, rightElement, titleStyle } = this.props;
    return (
      <Card
        style={
          window.location.pathname === "/explore" ? explorePageStyle : pageStyle
        }
      >
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{ marginLeft: "1em", marginTop: "0.5em", ...titleStyle }}
            >
              {title}
            </Box>
            <Box style={{ marginRight: "1em", marginTop: "0.5em" }}>
              {rightElement}
            </Box>
          </Box>
        </Box>
        <CardContent style={{ padding: "0" }}>{children}</CardContent>
      </Card>
    );
  }
}

export default CardContainer;
