import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import Utils from "../../../Common/Utils";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import DashboardCard from "../../../Components/Card/DashboardCard";
import NoOfExceptionLineChart from "../../../Components/Charts/NoOfExceptionLineChart";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import NoRecords from "../../../Components/NoRecords/NoRecords";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import { DefaultChartProps } from "../../../Models/DashboardModel";
import DashboardService from "../../../Services/DashboardService";
import exceptionsChart from "../../../Static/exceptionsChart.png";
import ExpandOpen from "../../../Static/ExpandOpen.png";
import exceptionsIcon from "../../../Static/No_of_exception.svg";
import { isEqual } from "lodash";
const DashboardServices = new DashboardService();

const chartCardColor = ["#4791FF", "#45BA7F", "#8B83FB", "#EF4A5F", "#F69A19"];

interface Props extends DefaultChartProps {}
interface State {
  showNoOfExceptionDateDialog: boolean;
  selectedDate?: string | null;
  selectedSourceValues: SelectModel[];
  sourceMappingData: any;
  alertType: AlertColor;
  openAlert: boolean;
  alertMsg: string;
  xAxisCategories: string[];
  chartData: any;
  isSearchButtonLoading: boolean;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  isLoading: boolean;
  resultArr: any;
  dashboardPercent: number | undefined;
  dashboardTotalCount: number | undefined;
  isCardLoading: boolean;
}

class NoOfExceptionsChart extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showNoOfExceptionDateDialog: false,
      selectedDate: null,
      selectedSourceValues: this.props.defaultSources,
      sourceMappingData: this.props.sourceDropDownValues,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isSearchButtonLoading: false,
      selectedStartDate: this.props.defaultStartDate,
      selectedEndDate: this.props.defaultEndDate,
      chartData: [],
      xAxisCategories: [],
      resultArr: [],
      isLoading: false,
      dashboardPercent: undefined,
      dashboardTotalCount: undefined,
      isCardLoading: false,
    };
  }
  async componentDidMount() {
    this.getChartData();
  }
  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (
      prevState.showNoOfExceptionDateDialog !==
      this.state.showNoOfExceptionDateDialog
    ) {
      if (this.state.showNoOfExceptionDateDialog === true) {
        this.setState(
          {
            selectedStartDate: this.props.defaultStartDate,
            selectedEndDate: this.props.defaultEndDate,
            selectedSourceValues: this.props.defaultSources,
            sourceMappingData: this.props.sourceDropDownValues,
          },
          () => {
            this.getChartData();
          }
        );
      }
    }
    if (
      !isEqual(prevProps.defaultSources, this.props.defaultSources) ||
      !isEqual(prevProps.defaultEndDate, this.props.defaultEndDate)
    ) {
      this.setState(
        {
          selectedStartDate: this.props.defaultStartDate,
          selectedEndDate: this.props.defaultEndDate,
          selectedSourceValues: this.props.defaultSources,
        },
        () => {
          this.getChartData();
        }
      );
    }
  }

  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedStartDate: moment(value).format("YYYY-MM-DD"),
        selectedEndDate: null,
      });
    }
  };
  handleEndDateChange = (value: string | null) => {
    if (this.state.selectedStartDate) {
      const startDate = new Date(this.state.selectedStartDate);
      const endDate = new Date(value!);
      const currentDate = new Date();

      const diffInDays =
        moment(moment(endDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true).diff(
          moment(moment(startDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true),
          "days"
        ) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          selectedEndDate: moment(value).format("YYYY-MM-DD 23:59:59.999"),
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be between 7 and 365 days",
          alertType: "error",
          selectedEndDate: null,
        });
      }
    }
  };

  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 5) {
      if (targetId) {
        this.setState({ selectedSourceValues: selected });
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };
  isEmptyCheck = () => {
    const { selectedStartDate, selectedEndDate, selectedSourceValues } =
      this.state;
    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  isResetEmptyCheck = () => {
    const { selectedStartDate, selectedEndDate, selectedSourceValues } =
      this.state;
    if (
      selectedStartDate ||
      selectedEndDate ||
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  onResetClick = () => {
    this.setState({
      selectedSourceValues: [],
      selectedEndDate: null,
      selectedStartDate: null,
    });
  };
  handleSearchClick = () => {
    this.setState(
      {
        isSearchButtonLoading: true,
      },
      () => {
        this.getChartData();
      }
    );
  };
  formatDateInterval = (
    diffDays: number,
    min: any,
    max: any,
    intervals: number
  ) => {
    const intervalBoundaries: any = [];

    const intervalLength = Math.ceil(diffDays / intervals);
    for (let i = 0; i < diffDays; i += intervalLength) {
      const intervalStart = new Date(min.getTime() + i * 24 * 60 * 60 * 1000);
      let intervalEnd = new Date(
        intervalStart.getTime() + (intervalLength - 1) * 24 * 60 * 60 * 1000
      );
      intervalEnd = new Date(Math.min(intervalEnd.getTime(), max.getTime()));
      const formattedStart = moment(intervalStart, "YYYY-MM-DD", true).format(
        "DD MMM"
      );
      const formattedEnd = moment(intervalEnd, "YYYY-MM-DD", true).format(
        "DD MMM"
      );

      if (diffDays <= 12) {
        intervalBoundaries.push(formattedStart);
      } else {
        formattedStart === formattedEnd
          ? intervalBoundaries.push(formattedStart)
          : intervalBoundaries.push(`${formattedStart}-${formattedEnd}`);
      }
    }
    return intervalBoundaries;
  };
  getChartData = async () => {
    try {
      const {
        selectedSourceValues,
        selectedEndDate,
        selectedStartDate,
        showNoOfExceptionDateDialog,
      } = this.state;
      this.setState({ isLoading: true });

      let sourceVal = selectedSourceValues
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      let parListSourceVal = selectedSourceValues
        .map((el) => el.value)
        .join(",");
      let defaultSourceValue = this.props.defaultSources
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();

      if (showNoOfExceptionDateDialog) {
        const payloadObj = {
          par_group_source: 0,
          par_list: parListSourceVal,
          par_start_date: selectedStartDate,
          par_end_date: selectedEndDate,
          par_whereclause: `source in (${sourceVal}) and CAST(exception_datetime as DATE) BETWEEN ''${selectedStartDate}'' and ''${selectedEndDate}''`,
          par_whereclause1: null,
        };

        const response = await DashboardServices.getnoexceptionData(payloadObj);
        if (response.result.length > 0) {
          const result = response.result.sort((a: any, b: any) => {
            if (a.source !== b.source) {
              return a.source.localeCompare(b.source);
            }
            const dateA: any = a.exception_date
              ? new Date(a.exception_date)
              : new Date(0);
            const dateB: any = b.exception_date
              ? new Date(b.exception_date)
              : new Date(0);
            return dateA - dateB;
          });
          const min = new Date(selectedStartDate!);
          const max = new Date(selectedEndDate!);

          const diffDays = Math.abs(Utils.getDaysBetweenDates(max, min)) + 1;
          const intervals =
            diffDays <= 12
              ? diffDays
              : diffDays <= 14
              ? 7
              : diffDays % 2 === 0
              ? 7
              : 6;
          const intervalLength = Math.ceil(diffDays / intervals);

          const intervalBoundaries: any = [];
          for (let i = 0; i < diffDays; i += intervalLength) {
            const intervalStart = new Date(
              min.getTime() + i * 24 * 60 * 60 * 1000
            );
            let intervalEnd = new Date(
              intervalStart.getTime() +
                (intervalLength - 1) * 24 * 60 * 60 * 1000
            );
            intervalEnd = new Date(
              Math.min(intervalEnd.getTime(), max.getTime())
            );

            const formattedStart = moment(intervalStart, "YYYY-MM-DD", true);
            const formattedEnd = moment(intervalEnd, "YYYY-MM-DD", true);
            if (diffDays <= 12) {
              intervalBoundaries.push(formattedStart);
            } else {
              // Add the formatted date range to the array
              intervalBoundaries.push(`${formattedStart}-${formattedEnd}`);
            }
          }

          const dailyRowCountSums: any = {};
          if (diffDays <= 12) {
            for (const item of response.result) {
              // Convert the date string to a Date object
              const date = new Date(item.exception_date);
              for (const day of intervalBoundaries) {
                if (
                  moment(date).format("YYYY-MM-DD") ===
                  moment(day).format("YYYY-MM-DD")
                ) {
                  if (!dailyRowCountSums[item.source]) {
                    dailyRowCountSums[item.source] = {
                      data: [],
                      days: [],
                      completed: [],
                      noaction: [],
                    };
                  }
                  if (!dailyRowCountSums[item.source].days.includes(day)) {
                    dailyRowCountSums[item.source].days.push(day);
                    dailyRowCountSums[item.source].data.push(
                      Number(item.totalcount) || 0
                    );
                    dailyRowCountSums[item.source].completed.push(
                      Number(item.completed) || 0
                    );
                    dailyRowCountSums[item.source].noaction.push(
                      Number(item.noactionrequired) || 0
                    );
                  } else {
                    const index =
                      dailyRowCountSums[item.source].days.indexOf(day);
                    dailyRowCountSums[item.source].data[index] +=
                      Number(item.totalcount) || 0;
                    dailyRowCountSums[item.source].completed[index] +=
                      Number(item.completed) || 0;

                    dailyRowCountSums[item.source].noaction[index] +=
                      Number(item.noactionrequired) || 0;
                  }
                }
              }
            }
          } else {
            for (const item of result) {
              const date = new Date(item.exception_date);
              for (const range of intervalBoundaries) {
                const [startStr, endStr] = range.split("-");
                const start = new Date(startStr);
                let end = new Date(endStr);

                end.setHours(23, 59, 59);

                if (date >= start && date <= end) {
                  if (!dailyRowCountSums[item.source]) {
                    dailyRowCountSums[item.source] = {
                      data: Array(intervalBoundaries.length).fill(0),
                      completed: Array(intervalBoundaries.length).fill(0),
                      noaction: Array(intervalBoundaries.length).fill(0),
                    };
                  }

                  const index = intervalBoundaries.indexOf(range);
                  dailyRowCountSums[item.source].data[index] +=
                    Number(item.totalcount) || 0;
                  dailyRowCountSums[item.source].completed[index] +=
                    Number(item.completed) || 0;
                  dailyRowCountSums[item.source].noaction[index] +=
                    Number(item.noactionrequired) || 0;
                  break;
                }
              }
            }
          }
          const transformedResponse = Object.keys(dailyRowCountSums).map(
            (key) => {
              return {
                spnd_src_sys_cd: key,
                data: dailyRowCountSums[key].data,
                days: this.formatDateInterval(diffDays, min, max, intervals),
                noactionrequired: dailyRowCountSums[key].noaction,
                completed: dailyRowCountSums[key].completed,
              };
            }
          );

          let groupedData = transformedResponse.map((item, index) => {
            return {
              name: item.spnd_src_sys_cd,
              color: chartCardColor[index],
              marker: {
                symbol: "circle",
              },
              data: item.data,
              days: item.days,
              noactionrequired: item.noactionrequired,
              completed: item.completed,
              pointPlacement: "on",
            };
          });

          this.setState({
            chartData: groupedData,
            xAxisCategories: this.formatDateInterval(
              diffDays,
              min,
              max,
              intervals
            ),
            resultArr: response.result,
            isSearchButtonLoading: false,
            isLoading: false,
          });
        } else {
          this.setState({
            chartData: [],
            isSearchButtonLoading: false,
            isLoading: false,
          });
        }
      } else {
        this.setState({ isCardLoading: true });
        const payloadObjDashboardCard = {
          par_group_source: 1,
          par_list: parListSourceVal,
          par_start_date: this.props.defaultStartDate,
          par_end_date: this.props.defaultEndDate,
          par_whereclause: `s.source in (${defaultSourceValue}) and CAST(exception_datetime as DATE) BETWEEN ''${this.props.defaultStartDate}'' and ''${this.props.defaultEndDate}''`,
          par_whereclause1: `s.source in (${defaultSourceValue}) and CAST(exception_datetime as DATE) BETWEEN ''${moment(
            this.props.defaultStartDate
          )
            .subtract(1, "month")
            .format("YYYY-MM-DD")}'' and ''${moment(this.props.defaultEndDate)
            .subtract(1, "month")
            .format("YYYY-MM-DD")}''`,
        };
        const responseDashboardCard =
          await DashboardServices.getnoexceptionData(payloadObjDashboardCard);
        const result = responseDashboardCard.result;

        this.setState({
          dashboardPercent:
            ((result[0].totalcount - result[1].totalcount) /
              result[1].totalcount) *
              100 ===
            Infinity
              ? result[0].totalcount * 100
              : ((result[0].totalcount - result[1].totalcount) /
                  result[1].totalcount) *
                100,
          dashboardTotalCount: result[0].totalcount,
          isCardLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        chartData: [],
        isSearchButtonLoading: false,
        isLoading: false,
        isCardLoading: false,
      });
    }
  };
  handleModalClose = () => {
    this.setState({ showNoOfExceptionDateDialog: false }, () => {
      this.getChartData();
    });
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;

    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  render() {
    const {
      showNoOfExceptionDateDialog,
      selectedSourceValues,
      sourceMappingData,
      alertMsg,
      alertType,
      openAlert,
      selectedStartDate,
      selectedEndDate,
      isSearchButtonLoading,
      chartData,
      resultArr,
      isLoading,
      xAxisCategories,
      dashboardPercent,
      dashboardTotalCount,
      isCardLoading,
    } = this.state;

    return (
      <>
        <Grid
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({ showNoOfExceptionDateDialog: true });
          }}
        >
          {isCardLoading ? (
            <Card
              sx={{
                borderRadius: 2,
                borderWidth: "4px",
                backgroundColor: "white",
              }}
            >
              <Grid
                item
                style={{ height: "125px", width: "420px" }}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress
                  disableShrink
                  sx={{ color: "#d52b1e", marginTop: 4 }}
                />
              </Grid>
            </Card>
          ) : (
            <DashboardCard
              graphImg={exceptionsChart}
              headingIcon={exceptionsIcon}
              headingText="Number of Exceptions"
              expandImg={ExpandOpen}
              graphNumbers={dashboardTotalCount}
              pricePercentText={dashboardPercent || 0}
              icon={
                dashboardPercent === 0 ? (
                  <></>
                ) : dashboardPercent === 0 ? (
                  ""
                ) : dashboardPercent! > 0 ? (
                  <NorthIcon
                    style={{
                      top: "2px",
                      position: "relative",
                      fontSize: "15px",
                      color: "green",
                    }}
                  />
                ) : (
                  <SouthIcon
                    style={{
                      top: "2px",
                      position: "relative",
                      fontSize: "15px",
                      color: "red",
                    }}
                  />
                )
              }
            />
          )}
        </Grid>

        <ModalDialog
          isOpen={showNoOfExceptionDateDialog}
          blackTitleColor
          title="Number of Exceptions"
          onClose={() => this.setState({ showNoOfExceptionDateDialog: false })}
          dialogWidth="xl"
          scrollBar={showNoOfExceptionDateDialog}
        >
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={2}>
                <h3
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  Errors Per Source
                </h3>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "-30px",
                }}
              >
                <Grid container columnGap={1} justifyContent={"flex-end"}>
                  <Grid item xs={4.5}>
                    <InputLabel>Select Sources</InputLabel>
                    <MultiAutoComplete
                      id="sourceName"
                      label="Select Sources"
                      selected={selectedSourceValues}
                      values={sourceMappingData}
                      onChange={this.handleMultiSelectChange}
                    />
                  </Grid>
                  <Grid item xs={1.68}>
                    <InputLabel> Select Start Date</InputLabel>
                    <DatePicker
                      width="12.5rem"
                      name="startDate"
                      placeHolder="Select Date"
                      maxDate={new Date()}
                      value={selectedStartDate}
                      onChange={this.handleStartDateChange}
                    />
                  </Grid>
                  <Grid item xs={1.68} className="EndDate">
                    <InputLabel> Select End Date</InputLabel>
                    <DatePicker
                      width="12.5rem"
                      disabled={!selectedStartDate}
                      name="endDate"
                      placeHolder="Select Date"
                      minDate={new Date(selectedStartDate!)}
                      maxDate={this.calculateMaxDate(selectedStartDate)}
                      value={selectedEndDate}
                      onChange={this.handleEndDateChange}
                    />
                  </Grid>
                  <Grid item xs={0.65} mt={3.2}>
                    <GradientButton
                      disabled={this.isEmptyCheck()}
                      isButtonLoad={isSearchButtonLoading}
                      label="Search"
                      onClick={this.handleSearchClick}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2}>
                    <RedButton
                      disabled={this.isResetEmptyCheck()}
                      label="Reset"
                      onClick={this.onResetClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isLoading ? (
                <Grid
                  item
                  style={{ height: "20em" }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress
                    disableShrink
                    sx={{ color: "#d52b1e", marginTop: 16 }}
                  />
                </Grid>
              ) : (
                <>
                  {this.state.chartData.length > 0 ? (
                    <NoOfExceptionLineChart
                      chartDataArr={chartData}
                      xAxisCategories={xAxisCategories}
                      resultArr={resultArr}
                    />
                  ) : (
                    <Box
                      style={{
                        position: "sticky",
                        left: "50%",
                        marginTop: "20vh",
                        marginBottom: "20vh",
                      }}
                      width={"82px"}
                    >
                      <NoRecords msg="No Data" />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </ModalDialog>
      </>
    );
  }
}
export default NoOfExceptionsChart;
