import {
  faTimes as CloseIcon,
  faTimesCircle as CancelIcon,
  faChevronDown as ExpandMoreIcon,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import SelectModel from "./SelectModel";

interface Props {
  disabled?: boolean;
  id?: string;
  label: string;
  selected?: SelectModel | any;
  values: SelectModel[];
  isRequired: boolean;
  onChange: (selected: SelectModel, targetId?: string) => void;
}

interface State {}

class SingleAutoComplete extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
    isRequired: true,
  };

  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {};
  }

  handleChange = (event: React.ChangeEvent<{}>, selectedValue: SelectModel) => {
    const { id } = this.props;
    this.props.onChange(selectedValue, id);
  };

  render() {
    const { id, label, values, isRequired, selected, disabled } = this.props;
    const labelId = id ? id : label;
    let closeIconStyle: React.CSSProperties = {
      color: "#FFFFFF",
    };
    let arrowDropDownIconStyle: React.CSSProperties = { color: "#FFFFFF" };
    let cancelIconStyle: React.CSSProperties = { color: "#EE3A29" };

    return (
      <Autocomplete
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        size="small"
        id={labelId}
        options={values}
        value={selected}
        ChipProps={{
          style: { borderColor: "#EE3A29", backgroundColor: "#FFFFFF" },
          deleteIcon: (
            <FontAwesomeIcon
              icon={CancelIcon}
              size="xs"
              style={cancelIconStyle}
            />
          ),
        }}
        getOptionLabel={(option) => option.text}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label}
            required={isRequired}
            variant="outlined"
            InputLabelProps={{
              style: {
                marginLeft: 5,
                maxWidth: "400px",
                overflowX: "scroll",
                color: "red",
              },
            }}
          />
        )}
        onChange={this.handleChange}
        popupIcon={
          <FontAwesomeIcon
            icon={ExpandMoreIcon}
            size="xs"
            style={arrowDropDownIconStyle}
          />
        }
        clearIcon={
          <FontAwesomeIcon icon={CloseIcon} size="xs" style={closeIconStyle} />
        }
        slotProps={{ popper: { sx: { zIndex: "99999999999999!important" } } }}
      />
    );
  }
}

export default SingleAutoComplete;
