import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDown from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUp from "@mui/icons-material/ArrowDropUpOutlined";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import * as React from "react";

import Utils from "../../Common/Utils";
import { RoleContext, UserLoginContext } from "../../Contexts";
import AuthProviderService from "../../Services/AuthProviderService";
import GradientButton from "../Button/GradientButton";

interface Props {
  showMenu: boolean;
}
interface State {
  open: boolean;
  anchorEl: any;
  userName: string;
  showRoles: boolean;
  prvContext: boolean;
}

class AccountInfo extends React.Component<Props, State> {
  static contextType = UserLoginContext;
  context!: React.ContextType<typeof UserLoginContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      userName: "",
      showRoles: false,
      prvContext: false,
    };
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      this.context.isUserLoggedIn &&
      this.state.prvContext !== this.context.isUserLoggedIn
    ) {
      const isLoggedIn = await AuthProviderService.isLoggedIn();

      if (isLoggedIn) {
        AuthProviderService.getAccessToken()
          .then((token) => {
            const decodedToken = JSON.stringify(
              Utils.decodeJWT(token.accessToken)
            );

            const userName = JSON.parse(decodedToken).name;
            this.setState({ userName, prvContext: true });
          })
          .catch((er) => console.log(er));
      }
    }
  }
  onToggleCheckbox = () => {};

  render() {
    const { anchorEl, userName, showRoles } = this.state;

    const open = Boolean(anchorEl);
    return (
      <>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="My Account">
            <IconButton
              onClick={
                window.location.pathname === "/lockedview"
                  ? () => {}
                  : this.handleClick
              }
              size="small"
              sx={{
                ml: 2,
                cursor:
                  window.location.pathname === "/lockedview"
                    ? "default"
                    : "pointer",
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <AccountCircleIcon
                sx={{ width: "2rem", height: "2rem" }}
                style={{
                  color: "#FFFFFF",
                }}
              />
              {open ? (
                <ArrowDropUp
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              ) : (
                <ArrowDropDown
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <Avatar /> {userName}
          </MenuItem>

          <Divider />
          {this.props.showMenu ? (
            <RoleContext.Consumer>
              {({ userRoles }) => (
                <>
                  <MenuItem
                    onClick={() => {
                      this.setState({ showRoles: !showRoles });
                    }}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>Roles</Box>
                    {showRoles ? (
                      <ArrowDropUp
                        style={{
                          color: "black",
                        }}
                      />
                    ) : (
                      <ArrowDropDown
                        style={{
                          color: "black",
                        }}
                      />
                    )}
                  </MenuItem>
                  {showRoles ? (
                    <MenuItem
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {userRoles.map((el) => {
                        return <Box>{el}</Box>;
                      })}
                    </MenuItem>
                  ) : null}
                  <Divider />
                </>
              )}
            </RoleContext.Consumer>
          ) : null}

          <MenuItem style={{ display: "flex", justifyContent: "center" }}>
            <GradientButton
              id="button"
              label="Logout"
              onClick={this.handleLogout}
            />
          </MenuItem>
        </Menu>
      </>
    );
  }
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleLogout = async () => {
    localStorage.clear();
    this.setState({ prvContext: false }, () => {
      this.context.setIsLoggedIn(false);
      AuthProviderService.logout();
    });
  };
}
export default AccountInfo;
