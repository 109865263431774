import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Component } from "react";

interface Props {
  chartDataArr: any;
  xAxisCategories: string[];
  resultArr: any;
}
class LineKOChart extends Component<Props> {
  render() {
    let chartDataArr = this.props.chartDataArr;
    const chartData: Highcharts.Options = {
      chart: {
        type: "areaspline",
      },

      title: {
        text: "",
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
        align: "left",
      },
      subtitle: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",

        formatter: function () {
          const startIdx = JSON.stringify(this.color).indexOf("[[");
          const endIdx = JSON.stringify(this.color).indexOf("]]") + 2;
          const stopsString = JSON.stringify(this.color).substring(
            startIdx,
            endIdx
          );
          let srcName = chartDataArr.find(
            (el: any) => el.color.stops[0][1] === JSON.parse(stopsString)[0][1]
          ).name;
          return `
                  <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
                 <b>${this.x}</b><br><br>
                  <span style=color:#BFBFBF>Src Sys Cd</span> <span style=padding-left:10px>${srcName}</span><br>
                  <span style=color:#BFBFBF>Total No. Of Count</span> <span style=padding-left:10px>${this.y}</span><br>
                 </div>
                  `;
        },
      },
      xAxis: {
        categories: this.props.xAxisCategories,
        tickmarkPlacement: "on",
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "",
        },
        startOnTick: true,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.3,
          dataLabels: {
            enabled: false,
          },
          lineWidth: 3, // Increase line width for smoother appearancelinecap: "round",
          linecap: "round",
          enableMouseTracking: true,
        },
      },
      series: this.props.chartDataArr,
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      </div>
    );
  }
}

export default LineKOChart;
