import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
type Props={
  dataValue:string|null
}
const AODSCorrectedRecords = (props:Props) => {
  const dataValue = Number(props.dataValue);
  const chartOptions = {
    chart: {
      type: "pie",
      marginLeft: -160,
      marginTop: -260,
    },

    title: {
      text: "<Typography>Passed Records</Typography>",
      x: -55,
      y: 50,
      align: "right", // Align the title text to the given direction
      style: {
        fontSize: "13px",
      },
    },
    subtitle: {
      text: "Measure the Weekly Passed<br/> Summary of Records. ",
      x: -50,
      y: 70,
      align: "right",
      style: {
        fontSize: "10px",
        color: "#7E84A3",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        animation: false,
        states: {
          hover: {
            enabled: false, // Disable hover effects for the donut chart
          },
        },
        center: ["50%"],
        align: "left",
        size: "20%",
        innerSize: "80%", // Set the innerSize to create a hole in the center
        dataLabels: {
          distance: -55,
          color: "black",
          format: " {point.percentage:.1f}%",
        },
        showInLegend: true,
      },
    },
    legend: {
      enabled: false,
      align: "right", // Set the alignment to the left
      verticalAlign: "middle", // Set the vertical alignment to the middle
      layout: "vertical",
      itemMarginTop: 0,
      itemMarginBottom: 0,
      itemStyle: {
        fontSize: "10px", // Set font size for legend items
      },
    },

    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#2E2F30",

      formatter: function () {
        return `
                <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
               <span style=color:#BFBFBF; padding-bottom:10px>Corrected Records :</span> <span style=padding-left:10px>${props.dataValue || 0}</span><br>
               </div>
                `;
      },
    },
    series: [
      {
        name: "Data",
        data: [
          {
            name: "Value",
            y: dataValue,
            color: "#02BC77", // Color for the center value
            size: "90%",
            dataLabels: {
              format: dataValue.toString(), // Display the value in the center
              style: {
                fontSize: "15px", // Set font size for the center value
                color: "#000000",
              },
            },
          },
          
        ],
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default AODSCorrectedRecords;
