import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import { isEqual } from "lodash";
import moment from "moment";
import { Component } from "react";
import Utils from "../../../Common/Utils";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import AODSCorrectedRecords from "../../../Components/Charts/AODSCorrectedRecords";
import AODSDialogChart from "../../../Components/Charts/AODSDialogChart";
import AODSFailedRecordsChart from "../../../Components/Charts/AODSFailedRecordsChart";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import NoRecords from "../../../Components/NoRecords/NoRecords";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import PageHeader from "../../../Components/Text/PageHeader";
import { AODSResponse } from "../../../Models/DashboardModel";
import DashboardService from "../../../Services/DashboardService";
import ExpandOpen from "../../../Static/ExpandOpen.png";
const DashboardServices = new DashboardService();
// const passedColors = ["#268B68", "#B2BF12", "#8B83FB", "#0099E6", "#2EDE1F"];
// const failedColors = ["#B95111", "#C25DD3", "#F69A19", "#808080", "#EF4A5F"];
type Props = {
  defaultSources: SelectModel[];
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  sourceDropDownValues: SelectModel[];
};

type State = {
  showAODSDialog: boolean;
  selectedSourceValues: SelectModel[];
  sourceMappingData: any;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  chartData: {
    color: string;
    data: number[];
    days: string[];
    marker: any;
    name: string;
    pointPlacement: string;
  }[];
  xAxisCategories: string[];
  resultArr: any;
  isSearchButtonLoading: boolean;
  isLoading: boolean;
  totalRecords: AODSResponse;
  isCardLoading: boolean;
  cardChartData: {
    color: string;
    data: number[];
    days: string[];
    marker: any;
    name: string;
    pointPlacement: string;
  }[];
  cardXAxisCategories: string[];
  cardResultArr: any;
  cardTotalRecords: AODSResponse;
};

class AODSChart extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showAODSDialog: false,
      selectedSourceValues: this.props.defaultSources,
      sourceMappingData: this.props.sourceDropDownValues,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      selectedStartDate: this.props.defaultStartDate,
      selectedEndDate: this.props.defaultEndDate,
      chartData: [],
      xAxisCategories: [],
      resultArr: [],
      isSearchButtonLoading: false,
      isLoading: false,
      totalRecords: {
        source: "",
        corrected_records: "",
        failed_records: "",
        processed_date: "",
      },
      isCardLoading: false,
      cardChartData: [],
      cardXAxisCategories: [],
      cardResultArr: [],
      cardTotalRecords: {
        source: "",
        corrected_records: "",
        failed_records: "",
        processed_date: "",
      },
    };
  }
  componentDidMount() {
    this.getChartData();
  }
  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (prevState.showAODSDialog !== this.state.showAODSDialog) {
      if (this.state.showAODSDialog === true) {
        this.setState(
          {
            selectedStartDate: this.props.defaultStartDate,
            selectedEndDate: this.props.defaultEndDate,
            selectedSourceValues: this.props.defaultSources,
            sourceMappingData: this.props.sourceDropDownValues,
          },
          () => {
            this.getChartData();
          }
        );
      }
    }
    if (
      !isEqual(prevProps.defaultSources, this.props.defaultSources) ||
      !isEqual(prevProps.defaultEndDate, this.props.defaultEndDate)
    ) {
      this.setState(
        {
          selectedStartDate: this.props.defaultStartDate,
          selectedEndDate: this.props.defaultEndDate,
          selectedSourceValues: this.props.defaultSources,
        },
        () => this.getChartData()
      );
    }
  }

  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedStartDate: moment(value).format("YYYY-MM-DD"),
        selectedEndDate: null,
      });
    }
  };
  handleEndDateChange = (value: string | null) => {
    if (this.state.selectedStartDate) {
      const startDate = new Date(this.state.selectedStartDate);
      const endDate = new Date(value!);
      const currentDate = new Date();

      const diffInDays =
        moment(moment(endDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true).diff(
          moment(moment(startDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true),
          "days"
        ) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          selectedEndDate: moment(value).format("YYYY-MM-DD 23:59:59.999"),
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be between 7 and 365 days",
          alertType: "error",
          selectedEndDate: null,
        });
      }
    }
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 5) {
      if (targetId) {
        this.setState({ selectedSourceValues: selected });
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };
  formatDateInterval = (
    diffDays: number,
    min: any,
    max: any,
    intervals: number
  ) => {
    const intervalBoundaries: any = [];

    const intervalLength = Math.ceil(diffDays / intervals);
    for (let i = 0; i < diffDays; i += intervalLength) {
      const intervalStart = new Date(min.getTime() + i * 24 * 60 * 60 * 1000);
      let intervalEnd = new Date(
        intervalStart.getTime() + (intervalLength - 1) * 24 * 60 * 60 * 1000
      );
      intervalEnd = new Date(Math.min(intervalEnd.getTime(), max.getTime()));
      const formattedStart = moment(intervalStart, "YYYY-MM-DD", true).format(
        "DD MMM"
      );
      const formattedEnd = moment(intervalEnd, "YYYY-MM-DD", true).format(
        "DD MMM"
      );

      if (diffDays <= 12) {
        intervalBoundaries.push(formattedStart);
      } else {
        formattedStart === formattedEnd
          ? intervalBoundaries.push(formattedStart)
          : intervalBoundaries.push(`${formattedStart}-${formattedEnd}`);
      }
    }
    return intervalBoundaries;
  };
  getChartData = async () => {
    try {
      const {
        selectedSourceValues,
        selectedEndDate,
        selectedStartDate,
        showAODSDialog,
      } = this.state;
      if (showAODSDialog) {
        this.setState({ isLoading: true });
      } else {
        this.setState({ isCardLoading: true });
      }
      let sourceVal = selectedSourceValues
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      let parListSourceVal = selectedSourceValues
        .map((el) => el.value)
        .join(",");
      const payloadObj: any = {
        par_list: parListSourceVal,
        par_start_date: selectedStartDate,
        par_end_date: selectedEndDate,
        par_whereclause: `src_sys_cd in (${sourceVal}) and CAST(file_prcs_dt_500 as DATE) BETWEEN ''${selectedStartDate}'' and ''${selectedEndDate}''`,
      };

      const response = await DashboardServices.getAodsData(payloadObj);
      // Sample response
      if (response.result.length > 0) {
        const totalRecords = response.result.filter(
          (el: any) => el.source === "TotalCount"
        )[0];
        const result = response.result.sort((a: any, b: any) => {
          if (a.source !== b.source) {
            return a.source.localeCompare(b.source);
          }
          const dateA: any = a.processed_date
            ? new Date(a.processed_date)
            : new Date(0);
          const dateB: any = b.processed_date
            ? new Date(b.processed_date)
            : new Date(0);
          return dateA - dateB;
        });
        const min = new Date(selectedStartDate!); // start date
        const max = new Date(selectedEndDate!); // end date

        // Calculate the difference in days
        const diffDays = Math.abs(Utils.getDaysBetweenDates(max, min)) + 1;
        const intervals =
          diffDays <= 12
            ? diffDays
            : diffDays <= 14
            ? 7
            : diffDays % 2 === 0
            ? 7
            : 6;
        const intervalLength = Math.ceil(diffDays / intervals);

        // Initialize an array to store the interval boundaries
        const intervalBoundaries: any = [];
        // Calculate the interval boundaries
        for (let i = 0; i < diffDays; i += intervalLength) {
          const intervalStart = new Date(
            min.getTime() + i * 24 * 60 * 60 * 1000
          );
          let intervalEnd = new Date(
            intervalStart.getTime() + (intervalLength - 1) * 24 * 60 * 60 * 1000
          );
          intervalEnd = new Date(
            Math.min(intervalEnd.getTime(), max.getTime())
          ); // Ensure end date is not beyond max
          const formattedStart = moment(intervalStart, "YYYY-MM-DD", true);
          const formattedEnd = moment(intervalEnd, "YYYY-MM-DD", true);

          // If the date range is 12 days, format each day individually
          if (diffDays <= 12) {
            intervalBoundaries.push(formattedStart);
          } else {
            // Add the formatted date range to the array
            intervalBoundaries.push(`${formattedStart}-${formattedEnd}`);
          }
        }
        const passedRecords: any = {};
        const failedRecords: any = {};
        if (diffDays <= 12) {
          for (const item of result) {
            // Convert the date string to a Date object
            const date = new Date(item.processed_date);
            for (const day of intervalBoundaries) {
              if (
                moment(date).format("YYYY-MM-DD") ===
                moment(day).format("YYYY-MM-DD")
              ) {
                if (!passedRecords[item.source]) {
                  passedRecords[item.source] = {
                    data: [],
                    days: [],
                  };
                }
                if (!passedRecords[item.source].days.includes(day)) {
                  passedRecords[item.source].days.push(day);
                  passedRecords[item.source].data.push(item.corrected_records);
                } else {
                  const index = passedRecords[item.source].days.indexOf(day);
                  passedRecords[item.source].data[index] +=
                    item.corrected_records;
                }

                if (!failedRecords[item.source]) {
                  failedRecords[item.source] = {
                    data: [],
                    days: [],
                  };
                }
                if (!failedRecords[item.source].days.includes(day)) {
                  failedRecords[item.source].days.push(day);
                  failedRecords[item.source].data.push(item.failed_records);
                } else {
                  const index = failedRecords[item.source].days.indexOf(day);
                  failedRecords[item.source].data[index] += item.failed_records;
                }
              }
            }
          }
        } else {
          // Convert the date string to a Date object
          for (const item of result) {
            for (const range of intervalBoundaries) {
              const date = new Date(item.processed_date);
              const [startStr, endStr] = range.split("-");

              const start = new Date(startStr);
              // Determine the correct year for the end date
              let end = new Date(endStr);

              end.setHours(23, 59, 59);

              if (date >= start && date <= end) {
                if (!passedRecords[item.source]) {
                  passedRecords[item.source] = {
                    data: [],
                    days: [],
                  };
                }
                if (!passedRecords[item.source].days.includes(range)) {
                  passedRecords[item.source].days.push(range);
                  passedRecords[item.source].data.push(item.corrected_records);
                } else {
                  const index = passedRecords[item.source].days.indexOf(range);
                  passedRecords[item.source].data[index] +=
                    item.corrected_records;
                }

                if (!failedRecords[item.source]) {
                  failedRecords[item.source] = {
                    data: [],
                    days: [],
                  };
                }
                if (!failedRecords[item.source].days.includes(range)) {
                  failedRecords[item.source].days.push(range);
                  failedRecords[item.source].data.push(item.failed_records);
                } else {
                  const index = failedRecords[item.source].days.indexOf(range);
                  failedRecords[item.source].data[index] += item.failed_records;
                }
              }
            }
          }
        }
        const transformedResponse = Object.keys(passedRecords).map((key) => {
          return {
            spnd_src_sys_cd: key,
            data: passedRecords[key].data,
            days: this.formatDateInterval(diffDays, min, max, intervals),
          };
        });
        let passedData: any = transformedResponse.map(
          (item: any, index: any) => {
            return {
              type: "column",
              stack: item.spnd_src_sys_cd,
              name: "Passed",
              color: "#02BC77",
              data: item.data,
              days: item.days,
            };
          }
        );

        const modifyResponse = Object.keys(failedRecords).map((key) => {
          return {
            spnd_src_sys_cd: key,
            data: failedRecords[key].data,
            days: this.formatDateInterval(diffDays, min, max, intervals),
          };
        });
        let failedData: any = modifyResponse.map((item: any, index: any) => {
          return {
            type: "column",
            stack: item.spnd_src_sys_cd,
            name: "Failed",
            color: "#EF4A5F",
            data: item.data,
            days: item.days,
          };
        });
        if (showAODSDialog) {
          this.setState({
            chartData: [...passedData, ...failedData],
            totalRecords: totalRecords,
            xAxisCategories: this.formatDateInterval(
              diffDays,
              min,
              max,
              intervals
            ),
            resultArr: response.result,
            isSearchButtonLoading: false,
            isLoading: false,
            isCardLoading: false,
          });
        } else {
          this.setState({
            cardChartData: [...passedData, ...failedData],
            cardTotalRecords: totalRecords,
            cardXAxisCategories: this.formatDateInterval(
              diffDays,
              min,
              max,
              intervals
            ),
            cardResultArr: response.result,
            isCardLoading: false,
          });
        }
      } else {
        this.setState({
          chartData: [],
          isSearchButtonLoading: false,
          isLoading: false,
          isCardLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        chartData: [],
        isSearchButtonLoading: false,
        isLoading: false,
        isCardLoading: false,
      });
    }
  };
  isEmptyCheck = () => {
    const { selectedStartDate, selectedEndDate, selectedSourceValues } =
      this.state;
    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  isResetEmptyCheck = () => {
    const { selectedStartDate, selectedEndDate, selectedSourceValues } =
      this.state;
    if (
      selectedStartDate ||
      selectedEndDate ||
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  onResetClick = () => {
    this.setState({
      selectedSourceValues: [],
      selectedEndDate: null,
      selectedStartDate: null,
    });
  };
  handleSearchClick = () => {
    this.setState(
      {
        isSearchButtonLoading: true,
      },
      () => {
        this.getChartData();
      }
    );
  };
  handleModalClose = () => {
    this.setState({ showAODSDialog: false });
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;

    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  render() {
    const {
      showAODSDialog,
      selectedStartDate,
      selectedSourceValues,
      sourceMappingData,
      selectedEndDate,
      isSearchButtonLoading,
      chartData,
      xAxisCategories,
      totalRecords,
      isLoading,
      alertMsg,
      alertType,
      openAlert,
      cardChartData,
      cardTotalRecords,
      cardXAxisCategories,
      isCardLoading,
    } = this.state;
    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PageHeader
            label="AODS Raw Data"
            style={{
              fontSize: "16px",
              lineHeight: "1",
            }}
          />
          <Box style={{ display: "flex", gap: "1em", cursor: "pointer" }}>
            <img
              alt="ExpandOpen"
              src={ExpandOpen}
              width={"14px"}
              height={"14px"}
              style={{
                cursor: "pointer",
                zIndex: "9999",
              }}
              onClick={() => {
                this.setState({ showAODSDialog: true });
              }}
            />
          </Box>
        </Box>
        <Grid>
          {isCardLoading ? (
            <Grid
              item
              style={{ height: "30em" }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress
                disableShrink
                sx={{
                  color: "#d52b1e",
                  marginTop: 22,
                }}
              />
            </Grid>
          ) : (
            <>
              {cardTotalRecords.corrected_records ||
              cardTotalRecords.failed_records ? (
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={3}>
                      <AODSFailedRecordsChart
                        dataValue={cardTotalRecords.failed_records}
                      />
                    </Grid>
                    <Grid item xs={3} ml={"-10px"}>
                      <AODSCorrectedRecords
                        dataValue={cardTotalRecords.corrected_records}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={-33}>
                    <AODSDialogChart
                      chartData={cardChartData}
                      xAxisCategories={cardXAxisCategories}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12em",
                    marginBottom: "12em",
                  }}
                >
                  <NoRecords msg="No Data" />
                </Box>
              )}
            </>
          )}
        </Grid>

        <ModalDialog
          isOpen={showAODSDialog}
          blackTitleColor
          title="AODS Raw Data"
          onClose={() => this.setState({ showAODSDialog: false })}
          dialogWidth="xl"
          scrollBar={showAODSDialog}
        >
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "0 25px 25px",
            }}
          >
            <Grid container columnGap={1} justifyContent="flex-end">
              <Grid item xs={5}>
                <InputLabel>Select Sources</InputLabel>
                <MultiAutoComplete
                  id="sourceName"
                  label="Select Sources"
                  selected={selectedSourceValues}
                  values={sourceMappingData}
                  onChange={this.handleMultiSelectChange}
                />
              </Grid>
              <Grid item xs={1.68}>
                <InputLabel> Select Start Date</InputLabel>
                <DatePicker
                  width="12.5rem"
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedStartDate}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={1.68} className="EndDate">
                <InputLabel> Select End Date</InputLabel>
                <DatePicker
                  width="12.5rem"
                  disabled={!selectedStartDate}
                  name="endDate"
                  placeHolder="Select Date"
                  minDate={new Date(selectedStartDate!)}
                  maxDate={this.calculateMaxDate(selectedStartDate)}
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </Grid>
              <Grid item xs={0.65} mt={3.2}>
                <GradientButton
                  disabled={this.isEmptyCheck()}
                  isButtonLoad={isSearchButtonLoading}
                  label="Search"
                  onClick={this.handleSearchClick}
                />
              </Grid>
              <Grid item xs={0.8} mt={3.2}>
                <RedButton
                  disabled={this.isResetEmptyCheck()}
                  label="Reset"
                  onClick={this.onResetClick}
                />
              </Grid>
            </Grid>
          </Grid>
          {isLoading ? (
            <Grid
              item
              style={{ height: "20em" }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress
                disableShrink
                sx={{ color: "#d52b1e", marginTop: 16 }}
              />
            </Grid>
          ) : (
            <>
              {totalRecords.corrected_records || totalRecords.failed_records ? (
                <>
                  <Grid container item xs={8}>
                    {" "}
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <AODSFailedRecordsChart
                          dataValue={totalRecords.failed_records}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AODSCorrectedRecords
                          dataValue={totalRecords.corrected_records}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box mt={-33}>
                    <AODSDialogChart
                      chartData={chartData}
                      xAxisCategories={xAxisCategories}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  style={{
                    position: "sticky",
                    left: "50%",
                    marginTop: "20vh",
                    marginBottom: "20vh",
                  }}
                  width={"82px"}
                >
                  <NoRecords msg="No Data" />
                </Box>
              )}
            </>
          )}
        </ModalDialog>
      </>
    );
  }
}
export default AODSChart;
