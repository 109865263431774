import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import TodayIcon from "@mui/icons-material/Today";
import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import "../../../App.css";
import Utils from "../../../Common/Utils";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import DashboardCard from "../../../Components/Card/DashboardCard";
import HorizontalBarChart from "../../../Components/Charts/HorizontalBarChart";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import NoRecords from "../../../Components/NoRecords/NoRecords";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import { LastLoadDataPayloadModel } from "../../../Models/DashboardModel";
import DashboardService from "../../../Services/DashboardService";
import ExpandOpen from "../../../Static/ExpandOpen.png";
import chartImg from "../../../Static/chart.svg";
import dateIcon from "../../../Static/dateIcon.png";
import { isEqual } from "lodash";
const DashboardServices = new DashboardService();
const chartCardColor = ["#4791FF", "#45BA7F", "#8B83FB", "#EF4A5F", "#F69A19"];

interface Props {
  defaultSources: SelectModel[];
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  sourceDropDownValues: SelectModel[];
}
interface State {
  showLastLoadDateDialog: boolean;
  selectedSourceValues: SelectModel[];
  sourceMappingData: any;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  startDatePicker: string | null;
  endDatePicker: string | null;
  chartData: any;
  defaultChartData: any;
  xAxisCategories: string[];
  resultArr: any;
  isSearchButtonLoading: boolean;
  isLoading: boolean;
  selectedsourceval: any;
  isLoadingCard: boolean;
}

class LastLoadDataGraph extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showLastLoadDateDialog: false,
      selectedSourceValues: this.props.defaultSources,
      sourceMappingData: this.props.sourceDropDownValues,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      selectedStartDate: this.props.defaultStartDate,
      selectedEndDate: this.props.defaultEndDate,
      chartData: [],
      xAxisCategories: [],
      resultArr: [],
      isSearchButtonLoading: false,
      isLoading: false,
      selectedsourceval: [],
      isLoadingCard: false,
      startDatePicker: this.props.defaultStartDate,
      endDatePicker: this.props.defaultEndDate,
      defaultChartData: {},
    };
  }
  componentDidMount() {
    this.getLastLoadData();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (
      prevState.showLastLoadDateDialog !== this.state.showLastLoadDateDialog
    ) {
      if (this.state.showLastLoadDateDialog === true) {
        this.setState(
          {
            selectedStartDate: this.props.defaultStartDate,
            selectedEndDate: this.props.defaultEndDate,
            selectedSourceValues: this.props.defaultSources,
            startDatePicker: this.props.defaultStartDate,
            endDatePicker: this.props.defaultEndDate,
          },
          () => {
            this.getLastLoadData();
          }
        );
      }
    }
    if (
      !isEqual(prevProps.defaultSources, this.props.defaultSources) ||
      !isEqual(prevProps.defaultEndDate, this.props.defaultEndDate)
    ) {
      this.setState(
        {
          selectedStartDate: this.props.defaultStartDate,
          selectedEndDate: this.props.defaultEndDate,
          selectedSourceValues: this.props.defaultSources,
        },
        () => {
          this.getLastLoadData();
        }
      );
    }
  }

  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  handleEndDateChange = (value: string | null) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      const diffInDays =
        moment(moment(endDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true).diff(
          moment(moment(startDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true),
          "days"
        ) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD 23:59:59.999"),
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be between 7 and 365 days",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };

  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 5) {
      if (targetId) {
        this.setState({ selectedSourceValues: selected }, () => {});
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };

  getLastLoadData = async () => {
    const {
      selectedSourceValues,
      selectedEndDate,
      selectedStartDate,
      showLastLoadDateDialog,
    } = this.state;
    const payloadObj: LastLoadDataPayloadModel = {
      var_spnd_src_sys_cd: `{${selectedSourceValues
        .map((el) => el.text)
        .join(",")}}`,
      var_startdate: selectedStartDate,
      var_enddate: selectedEndDate,
    };
    try {
      if (showLastLoadDateDialog) {
        this.setState({ isLoading: true });
      } else {
        this.setState({ isLoadingCard: true });
      }
      const response = await DashboardServices.getLastLoadData(payloadObj);
      if (response.result.length > 0) {
        const responseData = response?.result;

        let result = await responseData.reduce((acc: any, record: any) => {
          if (!acc[record.spnd_src_sys_cd]) {
            acc[record.spnd_src_sys_cd] = {
              sourcename: record.spnd_src_sys_cd,
              dailyrowcountsum: 0,
              lastmonthdailyrowcountsum: 0,
              lastloaddate: null,
              differencebwdate: null,
              percentage: null,
            };
          }

          let source = acc[record.spnd_src_sys_cd];

          if (record.dailyrowcount > 0) {
            source.dailyrowcountsum += record.dailyrowcount;
            source.lastloaddate = new Date(record.date);
          }
          if (record.lastmonthdailyrowcount > 0) {
            source.lastmonthdailyrowcountsum += record.lastmonthdailyrowcount;
          }

          let currentDate = new Date(record.date);
          if (
            !source.differencebwdate ||
            currentDate > source.differencebwdate
          ) {
            source.differencebwdate = currentDate;
          }

          return acc;
        }, {});

        for (let source in result) {
          if (
            result[source].dailyrowcountsum ||
            result[source].lastmonthdailyrowcountsum
          ) {
            result[source].percentage =
              ((result[source].dailyrowcountsum -
                result[source].lastmonthdailyrowcountsum) /
                result[source].lastmonthdailyrowcountsum) *
                100 ===
              Infinity
                ? result[source].dailyrowcountsum * 100
                : ((result[source].dailyrowcountsum -
                    result[source].lastmonthdailyrowcountsum) /
                    result[source].lastmonthdailyrowcountsum) *
                  100;
          } else {
            result[source].percentage = 0;
          }
          if (result[source].lastloaddate !== null) {
            result[source].lastloaddate = result[source].lastloaddate
              .toISOString()
              .split("T")[0];
            result[source].differencebwdate = this.calculateYAxisPosition(
              result[source].lastloaddate,
              selectedStartDate,
              selectedEndDate
            );
          } else {
            result[source].differencebwdate = 0;
          }
        }
        const dataArr = Object.values(result).map((el: any, index: number) => {
          return {
            y: el.differencebwdate,
            color: chartCardColor[index],
            percentage: el.percentage,
            sourcename: el.sourcename,
            lastloaddate: el.lastloaddate,
            dailyrowcountsum: el.dailyrowcountsum,
          };
        });
        const mergedResult = [];
        for (const obj1 of selectedSourceValues) {
          let found = false; // Flag to track if a match is found
          for (const obj2 of dataArr) {
            if (obj1.text === obj2.sourcename) {
              if (obj2.dailyrowcountsum === 0 && obj2.lastloaddate === null) {
                const combinedObject = {
                  y: 0,
                  color: "#939393",
                  percentage: 0,
                  sourcename: obj1.text,
                  lastloaddate: "No Change",
                  dailyrowcountsum: obj2.dailyrowcountsum,
                  // Add more properties as needed
                };
                mergedResult.push(combinedObject);
              } else {
                mergedResult.push(obj2);
              }
              found = true;
              break; // Exit the loop once a match is found
            }
          }
          if (!found) {
            const combinedObject = {
              y: 0,
              color: "#939393",
              percentage: 0,
              sourcename: obj1.text,
              lastloaddate: "No Change",
              // Add more properties as needed
            };
            mergedResult.push(combinedObject);
          }
        }
        mergedResult.sort((a, b) => a.sourcename.localeCompare(b.sourcename));
        if (showLastLoadDateDialog) {
          this.setState({
            isLoading: false,
            isLoadingCard: false,
            isSearchButtonLoading: false,
            selectedsourceval: mergedResult,
            chartData: dataArr,
            xAxisCategories: Object.values(result).map(
              (elem: any) => elem.sourcename
            ),
            resultArr: Object.values(result),
          });
        } else {
          let filteredData = mergedResult.filter(
            (item) => item.lastloaddate !== "No Change"
          );
          let endDate = new Date(selectedEndDate!);
          let closest =
            filteredData.length > 0
              ? filteredData.reduce((a, b) => {
                  let aDate = new Date(a.lastloaddate).getTime();
                  let bDate = new Date(b.lastloaddate).getTime();
                  let endDateInMs = endDate.getTime();
                  return Math.abs(endDateInMs - aDate) <
                    Math.abs(endDateInMs - bDate)
                    ? a
                    : b;
                })
              : [];
          this.setState({
            isLoadingCard: false,
            selectedsourceval: mergedResult,
            defaultChartData: closest,
          });
        }
      } else {
        this.setState({
          isLoading: false,
          isLoadingCard: false,
          isSearchButtonLoading: false,
          selectedsourceval: [],
          chartData: [],
          xAxisCategories: [],
          resultArr: [],
        });
      }
    } catch (error) {
      console.log("unexpected error during fecthing:", error);
      this.setState({
        isLoading: false,
        isLoadingCard: false,
        isSearchButtonLoading: false,
      });
    }
  };
  // date duration calculation
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;

    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  calculateYAxisPosition = (
    lastLoadDate: any,
    startDate: any,
    endDate: any
  ) => {
    const min = 1;
    const max =
      endDate && startDate
        ? Math.abs(
            Utils.getDaysBetweenDates(new Date(startDate), new Date(endDate))
          )
        : 0;

    const intervals = max <= 12 ? max : max <= 14 ? 7 : max % 2 === 0 ? 7 : 6;
    const intervalLength = Math.ceil((max - min) / intervals);

    const intervalBoundaries: {
      intervalStart: number;
      intervalEnd?: number;
    }[] = [];

    for (let i = 0; i < intervals; i++) {
      const intervalStart = min + i * intervalLength;
      const intervalEnd = Math.min(max, intervalStart + intervalLength - 1);
      if (intervalStart < max)
        intervalStart !== intervalEnd
          ? intervalBoundaries.push({ intervalStart, intervalEnd })
          : intervalBoundaries.push({ intervalStart });
    }
    let yAxisPosition;
    let lastLoadDateInDays =
      Utils.getDaysBetweenDates(new Date(startDate), new Date(lastLoadDate)) +
      1;
    for (let i = 0; i < intervalBoundaries.length; i++) {
      if (
        lastLoadDateInDays >= intervalBoundaries[i].intervalStart &&
        lastLoadDateInDays <=
          (intervalBoundaries[i].intervalEnd ||
            intervalBoundaries[i].intervalStart)
      ) {
        yAxisPosition = intervalBoundaries[i].intervalStart;
        break;
      } else {
        yAxisPosition = lastLoadDateInDays;
      }
    }

    return yAxisPosition;
  };
  isEmptyCheck = () => {
    const { selectedSourceValues, startDatePicker, endDatePicker } = this.state;
    if (startDatePicker && endDatePicker && selectedSourceValues.length > 0) {
      return false;
    }
    return true;
  };
  onResetClick = () => {
    this.setState({
      selectedSourceValues: [],
      startDatePicker: null,
      endDatePicker: null,
    });
  };
  handleSearchClick = () => {
    this.setState(
      {
        isSearchButtonLoading: true,
        selectedStartDate: this.state.startDatePicker,
        selectedEndDate: this.state.endDatePicker,
      },
      () => {
        this.getLastLoadData();
      }
    );
  };
  render() {
    const {
      showLastLoadDateDialog,
      sourceMappingData,
      selectedSourceValues,
      selectedStartDate,
      selectedEndDate,
      alertMsg,
      alertType,
      openAlert,
      isSearchButtonLoading,
      isLoading,
      selectedsourceval,
      startDatePicker,
      endDatePicker,
      isLoadingCard,
      defaultChartData,
    } = this.state;
    return (
      <>
        <Grid
          mb={2}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({ showLastLoadDateDialog: true });
          }}
        >
          {isLoadingCard ? (
            <Card
              sx={{
                borderRadius: 2,
                borderWidth: "4px",
                backgroundColor: "white",
              }}
            >
              <Grid
                item
                style={{ height: "125px", width: "420px" }}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress
                  disableShrink
                  sx={{ color: "#d52b1e", marginTop: 4 }}
                />
              </Grid>
            </Card>
          ) : (
            <>
              {defaultChartData.lastloaddate ? (
                <DashboardCard
                  graphImg={chartImg}
                  headingIcon={dateIcon}
                  headingText={`Last Load Date - ${defaultChartData.sourcename}`}
                  expandImg={ExpandOpen}
                  graphNumbers={defaultChartData.lastloaddate}
                  pricePercentText={defaultChartData.percentage || 0}
                  icon={
                    defaultChartData.length === 0 ? (
                      <></>
                    ) : defaultChartData.percentage === 0 ? (
                      ""
                    ) : defaultChartData.percentage > 0 ? (
                      <NorthIcon
                        style={{
                          top: "2px",
                          position: "relative",
                          fontSize: "15px",
                          color: "green",
                        }}
                      />
                    ) : (
                      <SouthIcon
                        style={{
                          top: "2px",
                          position: "relative",
                          fontSize: "15px",
                          color: "red",
                        }}
                      />
                    )
                  }
                />
              ) : (
                <Card
                  sx={{
                    borderRadius: 2,
                    borderWidth: "4px",
                    backgroundColor: "white",
                    width: "25em",
                    height: "6.7em",
                    padding: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NoRecords msg="No Data" />
                </Card>
              )}
            </>
          )}
        </Grid>

        <ModalDialog
          isOpen={showLastLoadDateDialog}
          blackTitleColor
          title="Last Load Data Per Source"
          onClose={() =>
            this.setState({
              showLastLoadDateDialog: false,
            })
          }
          dialogWidth="xl"
          scrollBar={showLastLoadDateDialog}
        >
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />

          <Grid container>
            <Grid item xs={3}>
              <Grid
                container
                flexDirection={"column"}
                display="flex"
                alignItems="center"
                style={{
                  position: "relative",
                  maxHeight: "515px",
                  overflowY: "auto",
                }}
              >
                {isLoading ? (
                  ""
                ) : (
                  <Grid item mb={1.5}>
                    {selectedsourceval.map((el: any, ind: number) => {
                      return (
                        <Grid item mb={1} key={ind}>
                          <DashboardCard
                            iconChart={<TodayIcon />}
                            headingText={el.sourcename}
                            graphNumbers={
                              el.lastloaddate
                                ? el.lastloaddate
                                : moment(selectedEndDate).format("YYYY-MM-DD")
                            }
                            pricePercentText={el.percentage}
                            icon={
                              el.percentage === 0 &&
                              el.graphNumbers === "No Change" ? (
                                ""
                              ) : el.percentage > 0 ? (
                                <NorthIcon
                                  style={{
                                    top: "2px",
                                    position: "relative",
                                    fontSize: "15px",
                                    color: "green",
                                  }}
                                />
                              ) : (
                                <SouthIcon
                                  style={{
                                    top: "2px",
                                    position: "relative",
                                    fontSize: "15px",
                                    color: "red",
                                  }}
                                />
                              )
                            }
                            color={el.color}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={9}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0 5px 25px 5px",
                }}
              >
                <Grid container columnGap={1}>
                  <Grid item xs={5}>
                    <InputLabel>Select Sources</InputLabel>
                    <MultiAutoComplete
                      id="sourceName"
                      label="Select Sources"
                      selected={selectedSourceValues}
                      values={sourceMappingData}
                      onChange={this.handleMultiSelectChange}
                    />
                  </Grid>
                  <Grid item xs={2.2} ml={0.8}>
                    <InputLabel> Select Start Date</InputLabel>
                    <DatePicker
                      name="startDate"
                      placeHolder="Select Date"
                      maxDate={
                        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                      }
                      value={startDatePicker}
                      onChange={this.handleStartDateChange}
                    />
                  </Grid>
                  <Grid item xs={2.2} className="EndDate" ml={0.8}>
                    <InputLabel> Select End Date</InputLabel>
                    <DatePicker
                      disabled={!startDatePicker}
                      name="endDate"
                      placeHolder="Select Date"
                      minDate={new Date(startDatePicker!)}
                      maxDate={this.calculateMaxDate(startDatePicker)}
                      value={endDatePicker}
                      onChange={this.handleEndDateChange}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2} ml={0.8}>
                    <GradientButton
                      disabled={this.isEmptyCheck()}
                      isButtonLoad={isSearchButtonLoading}
                      label="Search"
                      onClick={this.handleSearchClick}
                    />
                  </Grid>
                  <Grid item xs={0.8} mt={3.2} ml={1}>
                    <RedButton label="Reset" onClick={this.onResetClick} />
                  </Grid>
                </Grid>
              </Grid>
              {isLoading ? (
                <Grid
                  item
                  style={{ height: "20em", width: "50em" }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress
                    disableShrink
                    sx={{ color: "#d52b1e", marginTop: 8 }}
                  />
                </Grid>
              ) : (
                <>
                  {this.state.resultArr.length > 0 && showLastLoadDateDialog ? (
                    <HorizontalBarChart
                      chartDataArr={this.state.chartData}
                      xAxisCategories={this.state.xAxisCategories}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      resultArr={this.state.resultArr}
                    />
                  ) : (
                    <Box
                      style={{
                        position: "sticky",
                        left: "50%",
                        marginTop: "25vh",
                        marginBottom: "25vh",
                      }}
                      width={"82px"}
                    >
                      <NoRecords msg="No Data" />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </ModalDialog>
      </>
    );
  }
}

export default LastLoadDataGraph;
