import { URIs } from "../Common/Constants";
import { AODSResponse, ExceptionByCountryResponse, ExceptionBySourceResponse, KickoutClinicalNonClinicalReponse, LastLoadDataPayloadModel } from "../Models/DashboardModel";

import GenericAPIService from "./GenericAPIService";

const { REPORTING_DEADLINE, LAST_LOAD_DATA, EXCEPTIONREPORT_DATA, EXCEPTIONPERCOUNTRY_DATA, KICKOUT_DATA,AODS_DATA } = URIs;
class DashboardService extends GenericAPIService {
  async getReportingData() {
    const data = await this.getAsync<{ result: any }>(REPORTING_DEADLINE);
    return data;
  }
  async getLastLoadData(body: LastLoadDataPayloadModel) {
    const { data } = await this.postAsync<{ result: any }>(
      LAST_LOAD_DATA,
      body
    );
    return data;
  }
  async getnoexceptionData(body: any) {
    const { data } = await this.postAsync<{ result: any }>(
      EXCEPTIONREPORT_DATA,
      body
    );
    return data;
  }
  async getExceptionData(body: any) {
    const { data } = await this.postAsync<{ result: ExceptionBySourceResponse[] }>(
      EXCEPTIONREPORT_DATA,
      body
    );
    return data;
  }
  async getCountryExceptionData(body: any) {
    const { data } = await this.postAsync<{ result: ExceptionByCountryResponse[] }>(
      EXCEPTIONPERCOUNTRY_DATA,
      body
    );
    return data;
  }
  async getkickoutSourceData(body: any) {
    const { data } = await this.postAsync<{ result: KickoutClinicalNonClinicalReponse[] }>(
      KICKOUT_DATA,
      body
    );
    return data;
  }
  async getAodsData(body: any) {
    const { data } = await this.postAsync<{ result: AODSResponse[] }>(
      AODS_DATA,
      body
    );
    return data;
  }
}

export default DashboardService;
