import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { Component } from "react";

type Props = {
  chartData: any;
  xAxisCategories: string[];
};

interface State {}

class AODSDialogChart extends Component<Props, State> {
  render() {
    const chartData: Highcharts.Options = {
      chart: {
        type: "column",
      },
      legend: {
        enabled: false,
        verticalAlign: "top",
        align: "right",
      },
      title: {
        text: "",
        align: "left",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.props.xAxisCategories,
      },
      yAxis: {
        type: "logarithmic",
        gridLineWidth: 0,
        allowDecimals: false,
        min: 1,
        title: {
          text: "",
        },
        stackLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return `<div> ${(this as any).stack}</div>`;
          },
          style: {
            writingMode: "vertical-lr",
            textOrientation: "mixed",
            fontSize: "0.6rem",
          },
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",
        formatter: function (): string {
          return ` 
            <div style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
              <b>${this.x}</b><br><br>
              <b>${this.series.name}</b><br>
              <span style="color:#BFBFBF; padding-bottom:10px">Src Sys Cd :</span> <span style="padding-left:10px">${this.point.series.options.stack}</span><br>
              <span style="color:#BFBFBF"> ${this.series.name} Record Count :</span> <span style="padding-left:10px">${this.y}</span><br>
            </div>`;
        },
      },
      plotOptions: {
        column: {
          pointWidth: 15,
        },
        series: {
          stacking: "normal",
        },
      },
      series: this.props.chartData,
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      </div>
    );
  }
}

export default AODSDialogChart;
